import { Link, /*useLocation*/ } from "react-router-dom"
import { useEffect, useState } from "react"
//import { toast } from "react-toastify"


const ButtonAccesJarvis = () => {
//  const location = useLocation()
  const [ jarvisButtonTitle, setJarvisButtonTitle ] = useState( "")
  const [ jarvisButtonLink, setJarvisButtonLink ] = useState( String(import.meta.env.VITE_MS_TEST_AUTH_APP_BIORCE_URL + "/signup"))

  //const searchParams = new URLSearchParams(window.location.search)

  /*const checkLogout = async () => {
      localStorage.setItem('memberPlanId', '')
      localStorage.setItem('appToken', '')

      toast.info("You have been successfully logged out.")
    }

    searchParams.get('logout') === 'true' ? checkLogout() : ''

  const getUserData = () => {
    const queryParams = new URLSearchParams(location.search)
    const newPlanId = queryParams.get('planId')
    const newToken = queryParams.get('token')
    
    if(newPlanId && newToken){
      localStorage.setItem('memberPlanId', newPlanId)
      localStorage.setItem('appToken', newToken)
   }
  }*/

  const getLocalStorageData = () => {
    //const planId = localStorage.getItem('memberPlanId')
    //const token = localStorage.getItem('appToken')
/*
    if (
      planId === import.meta.env.VITE_MS_TEST_BETA_TESTER_PLAN_ID &&
      token
    ) {
      setJarvisButtonTitle("Access Jarvis")
      setJarvisButtonLink(String(import.meta.env.VITE_MS_TEST_JARVIS_BIORCE_URL + `/init?token=${token}&jToken=${jToken}`))
    } else if (
      planId !== import.meta.env.VITE_MS_TEST_BETA_TESTER_PLAN_ID &&
      token
    ) {
      toast.info("You are in the waiting list to access Jarvis")
      setJarvisButtonTitle("Waiting list")
      setJarvisButtonLink("")
    } else {
     */
      //setJarvisButtonTitle( "Join Waiting list")
      //setJarvisButtonLink( String(import.meta.env.VITE_MS_TEST_AUTH_APP_BIORCE_URL + "/signup"))
      
      setJarvisButtonTitle("Access Jarvis")
      setJarvisButtonLink( String(import.meta.env.VITE_MS_TEST_AUTH_APP_BIORCE_URL + "/login"))
   // }
  }

  useEffect(() => {
    //getUserData()
    getLocalStorageData()
  }, [])

  return (
    <Link to={jarvisButtonLink} target="_blank">
        <button className="border border-[#00A19A]  
        xl:bg-transparent text-center w-[230px] rounded-[13px] h-14 relative font-sans text-[#001A4F] text-xl font-semibold group 

        bg-[linear-gradient(274deg,rgba(94,186,186,0.05)-10.87%,rgba(94,186,186,0.20)240.51%)] shadow-[0px_2px_4px_0px_rgba(18,55,104,0.12),0px_0px_0px_1px_rgba(94,186,186,0.50),0px_1px_0px_0px_rgba(94,186,186,0.80)] 

        backdrop-blur-[17.5px]">
            <div className="bg-white rounded-[13px] h-14 w-1/4 flex items-center justify-center absolute left-[-1px] top-[-1px] group-hover:w-[230px] z-10 duration-500 bg-gradient-to-b from-[rgba(94,186,186,0.80)] to-[#00A19A] dark:to-[#99ECF3]">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path className="dark:fill-[#161A1D]" d="M7.80864 6.03974C8.17904 6.18709 8.59331 6.27059 8.99782 6.30005C10.148 6.38355 11.0984 6.78139 11.7758 7.4641C12.4533 8.15172 12.848 9.10947 12.9309 10.2637C12.9406 10.3767 12.9504 10.4896 12.9699 10.5977C12.9991 10.8727 13.0673 11.1527 13.1794 11.4179C13.1843 11.4326 13.1892 11.4474 13.1941 11.4572C13.5255 12.2922 14.2273 12.8668 15.0412 13.1075C15.0655 13.1173 15.0899 13.1222 15.1143 13.132C15.1484 13.1419 15.1825 13.1468 15.2166 13.1566C15.9331 13.3285 16.7129 13.2303 17.3806 12.8079C17.4049 12.7931 17.4293 12.7735 17.4537 12.7539C17.4926 12.7293 17.5268 12.6998 17.5657 12.6753C18.3845 12.081 18.7793 11.2411 18.8183 10.2244C18.8427 9.51713 18.9889 8.86389 19.3495 8.26468C19.5347 7.96017 19.7394 7.69494 19.9636 7.4641C20.1927 7.23816 20.4558 7.02697 20.758 6.84524C21.3526 6.48178 22.0057 6.33444 22.7026 6.30988C23.7894 6.27059 24.6716 5.81872 25.2662 4.86588C26.1483 3.44152 25.6073 1.51619 24.1014 0.73034C22.61 -0.0456874 20.7093 0.622285 20.0952 2.17434C19.949 2.54762 19.8661 2.9651 19.8369 3.37276C19.754 4.53189 19.3593 5.48965 18.6818 6.17235C17.9995 6.85506 17.054 7.2529 15.9087 7.3364C15.8746 7.3364 15.8405 7.33148 15.8064 7.32657C15.1045 7.30201 14.4563 7.15467 13.8618 6.79121C13.5596 6.60457 13.2964 6.39829 13.0673 6.17235C12.8432 5.94151 12.6336 5.67629 12.4533 5.37177C12.0926 4.77256 11.9464 4.11441 11.922 3.41205C11.883 2.31677 11.4347 1.42778 10.4892 0.828571C9.0758 -0.0604222 7.16532 0.484761 6.38553 2.00243C5.61549 3.50537 6.27831 5.42088 7.81839 6.03974H7.80864Z" fill="#001A4F"/>
                    <path className="dark:fill-[#161A1D]" d="M23.9503 25.961C23.5799 25.8137 23.1657 25.7302 22.7611 25.7007C21.611 25.6172 20.6606 25.2194 19.9832 24.5367C19.3057 23.8491 18.9109 22.8913 18.8281 21.7371C18.8183 21.6241 18.8086 21.5112 18.7891 21.4031C18.7599 21.1281 18.6916 20.8481 18.5795 20.5829C18.5747 20.5681 18.5698 20.5534 18.5649 20.5436C18.2335 19.7086 17.5317 19.134 16.7178 18.8933C16.6934 18.8835 16.669 18.8786 16.6447 18.8687C16.6106 18.8589 16.5764 18.854 16.5423 18.8442C15.8259 18.6723 15.0461 18.7705 14.3784 19.1929C14.3541 19.2076 14.3297 19.2273 14.3053 19.2469C14.2663 19.2715 14.2322 19.301 14.1932 19.3255C13.3744 19.9198 12.9797 20.7597 12.9407 21.7764C12.9163 22.4837 12.7701 23.1369 12.4095 23.7361C12.2243 24.0406 12.0196 24.3058 11.7954 24.5367C11.5663 24.7626 11.3031 24.9738 11.001 25.1555C10.4064 25.519 9.7533 25.6663 9.05636 25.6909C7.96953 25.7302 7.0874 26.1821 6.49281 27.1349C5.61067 28.5593 6.15165 30.4846 7.65762 31.2704C9.14896 32.0465 11.0497 31.3785 11.6638 29.8264C11.81 29.4532 11.8928 29.0357 11.9221 28.628C12.0049 27.4689 12.3997 26.5111 13.0771 25.8284C13.7595 25.1457 14.705 24.7479 15.8503 24.6644C15.8844 24.6644 15.9185 24.6693 15.9526 24.6742C16.6544 24.6988 17.3026 24.8461 17.8972 25.2096C18.1994 25.3962 18.4626 25.6025 18.6916 25.8284C18.9158 26.0593 19.1254 26.3245 19.3057 26.629C19.6664 27.2282 19.8126 27.8864 19.8369 28.5887C19.8759 29.684 20.3243 30.573 21.2698 31.1722C22.6832 32.0612 24.5937 31.516 25.3734 29.9983C26.1435 28.4954 25.4807 26.5799 23.9406 25.961H23.9503Z" fill="#001A4F"/>
                    <path className="dark:fill-[#161A1D]" d="M7.40912 20.1359C8.09144 19.4532 9.0418 19.0554 10.1871 18.9719C10.2992 18.9621 10.4113 18.9523 10.5185 18.9326C10.7915 18.9031 11.0693 18.8344 11.3324 18.7214C11.3471 18.7165 11.3617 18.7116 11.3714 18.7067C12.1999 18.3727 12.775 17.6654 13.009 16.8452C13.0187 16.8206 13.0236 16.7961 13.0333 16.7715C13.0431 16.7371 13.048 16.7028 13.0577 16.6684C13.2283 15.9464 13.1308 15.1605 12.7117 14.4876C12.6971 14.4631 12.6776 14.4385 12.6581 14.414C12.6337 14.3747 12.6045 14.3403 12.5801 14.301C11.9904 13.4759 11.157 13.078 10.1481 13.0387C9.44632 13.0142 8.79812 12.8668 8.20353 12.5034C7.90136 12.3167 7.63819 12.1104 7.40912 11.8845C7.18493 11.6537 6.97536 11.3884 6.79504 11.0839C6.43439 10.4847 6.28818 9.82657 6.26381 9.12421C6.22482 8.02893 5.77644 7.13994 4.83094 6.54073C3.41758 5.65174 1.5071 6.19692 0.727308 7.7146C-0.0427338 9.21753 0.620086 11.133 2.16017 11.7519C2.53057 11.8992 2.94483 11.9827 3.34935 12.0122C4.49953 12.0957 5.4499 12.4935 6.12734 13.1763C6.80479 13.8639 7.19955 14.8167 7.28241 15.9709C7.28241 16.0053 7.27753 16.0397 7.27266 16.0741C7.24829 16.7813 7.10208 17.4346 6.74143 18.0338C6.55623 18.3383 6.35153 18.6035 6.12734 18.8344C5.89828 19.0603 5.6351 19.2715 5.33293 19.4532C4.73834 19.8167 4.08527 19.964 3.38834 19.9886C2.30151 20.0279 1.41937 20.4798 0.82478 21.4326C-0.0573564 22.857 0.483624 24.7823 1.98959 25.5681C3.48094 26.3442 5.38167 25.6762 5.99575 24.1241C6.14196 23.7509 6.22482 23.3334 6.25406 22.9257C6.33691 21.7666 6.73168 20.8088 7.40912 20.1261V20.1359Z" fill="#001A4F"/>
                    <path className="dark:fill-[#161A1D]" d="M29.5988 20.244C29.2284 20.0966 28.8141 20.0131 28.4096 19.9837C27.2594 19.9002 26.3091 19.5023 25.6316 18.8196C24.9542 18.132 24.5594 17.1792 24.4766 16.025C24.4766 15.9906 24.4863 15.9562 24.4863 15.9218C24.5107 15.2145 24.6569 14.5613 25.0175 13.9621C25.2027 13.6576 25.4074 13.3924 25.6316 13.1615C25.8607 12.9356 26.1239 12.7244 26.426 12.5427C27.0206 12.1792 27.6737 12.0319 28.3706 12.0073C29.4575 11.968 30.3396 11.5161 30.9342 10.5633C31.8163 9.13894 31.2753 7.21361 29.7694 6.42776C28.278 5.65173 26.3773 6.3197 25.7632 7.87176C25.617 8.24503 25.5341 8.66252 25.5049 9.07018C25.4221 10.2293 25.0273 11.1871 24.3498 11.8698C23.6675 12.5525 22.7172 12.9503 21.5718 13.0338C21.4597 13.0436 21.3477 13.0535 21.2404 13.0731C20.9675 13.1026 20.6897 13.1713 20.4265 13.2843C20.4119 13.2892 20.3973 13.2941 20.3875 13.299C19.559 13.633 18.9888 14.3403 18.75 15.1605C18.7402 15.1851 18.7354 15.2096 18.7256 15.2342C18.7159 15.2686 18.711 15.303 18.7012 15.3373C18.5307 16.0593 18.6281 16.8452 19.0473 17.5181C19.0619 17.5426 19.0814 17.5672 19.1009 17.5917C19.1253 17.631 19.1545 17.6654 19.1789 17.7047C19.7686 18.5299 20.602 18.9277 21.6108 18.967C22.3126 18.9915 22.9608 19.1389 23.5554 19.5023C23.8576 19.689 24.1208 19.8953 24.3498 20.1212C24.574 20.352 24.7836 20.6173 24.9639 20.9218C25.3246 21.521 25.4708 22.1791 25.4952 22.8815C25.5341 23.9768 25.9825 24.8658 26.928 25.465C28.3414 26.354 30.2519 25.8088 31.0317 24.2911C31.8017 22.7882 31.1389 20.8727 29.5988 20.2538V20.244Z" fill="#001A4F"/>
                </svg>
            </div>
            <p className="translate-x-2 font-TTBold font-semibold pl-[35px] text-[20px] md:text-[22px] xl:text-[22px] text-center dark:text-white gap-[10px] mx-auto">{jarvisButtonTitle}</p>
        </button>
    </Link>
  )
}

export default ButtonAccesJarvis