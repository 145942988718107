import { useState } from "react"
import SectionTitle from "../../components/sectionTitle/SectionTitle"
import "./Contact.css"
import SeoComponent from "../../components/SEO-Component/SeoComponent"

const Contact = () => {

  const [messageButton, setMessageButton] = useState("Send")
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [namePlaceholder, setNamePlaceholder] = useState('Full Name')
  const [mailPlaceholder, setMailPlaceholder] = useState('Email')
  
  const handleSubmit = async (event:any) => {
    event.preventDefault()

    const [firstname, ...lastnameArray] = fullName.split(" ")
    const lastname = lastnameArray.join(" ")

    const requestBody = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstname
      },
      {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastname
      },
      {
          objectTypeId: "0-1",
          name: "email",
          value: email
      },
      {
          objectTypeId: "0-1",
          name: "message",
          value: message
      }
      ],
    }

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${import.meta.env.VITE_PORTAL_ID}/${import.meta.env.VITE_FORM_GUID2}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      )
      if (response.ok) {
        const result = await response.json()
        setMessageButton(result.inlineMessage)
        setFullName("")
        setEmail("")
        setMessage("")
      }
    } catch (error) {  
      setMessageButton("Something went wrong, please try again.")
    }
  }


  return (
    <div>

      <SeoComponent page="contact"/>

      <main>
        <div className="2xl:max-w-7xl 2xl:mx-auto mt-[-20px]">
          <SectionTitle 
              title= "Hello" 
              icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M11.3666 12.4786L12.5268 12.5614C13.0349 12.5977 13.289 12.6159 13.479 12.5258C13.6458 12.4467 13.7801 12.3124 13.8591 12.1456C13.9492 11.9557 13.9311 11.7016 13.8948 11.1935L13.8119 10.0333C13.7986 9.847 13.7919 9.75385 13.7906 9.65881C13.7881 9.48673 13.786 9.57733 13.7966 9.40556C13.8025 9.31069 13.8557 8.86437 13.9622 7.97179C13.9872 7.76245 14 7.54939 14 7.33333C14 4.38781 11.6122 2 8.66667 2C6.83355 2 5.21644 2.92482 4.25645 4.33333M9.2 10.4C9.2 8.41174 7.58822 6.79997 5.6 6.79997C4.74393 6.79997 3.95766 7.09877 3.33978 7.59778C3.29519 7.63378 3.25148 7.67083 3.20869 7.70889C2.46715 8.3683 2 9.32958 2 10.4C2 10.5942 2.01538 10.7848 2.04498 10.9707C2.10863 11.3703 2.14046 11.5701 2.14597 11.6318C2.15425 11.7243 2.15335 11.7037 2.15312 11.7966C2.15296 11.8585 2.14765 11.9329 2.13702 12.0817L2.07103 13.0056C2.04653 13.3486 2.03428 13.52 2.09508 13.6483C2.14845 13.7609 2.23911 13.8515 2.35169 13.9049C2.47993 13.9657 2.65142 13.9534 2.99439 13.9289L3.91827 13.8629C4.06705 13.8523 4.14144 13.847 4.20336 13.8468C4.2963 13.8466 4.27565 13.8457 4.36821 13.854C4.42988 13.8595 4.62981 13.8914 5.0293 13.955C5.21517 13.9846 5.40579 14 5.6 14C6.67039 14 7.63166 13.5328 8.29108 12.7913C8.32913 12.7485 8.36617 12.7048 8.40217 12.6602C8.90119 12.0423 9.2 11.256 9.2 10.4Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />

          <div className="block md:flex lg:flex mx-10 lg:mx-20 mb-[40px] mt-10 lg:mt-0">
            <div className="w-full md:w-1/2 lg:w-1/2 h-[250px] lg:h-[300px]">
              <h1 className="text-[#001A4F] text-[16px] lg:text-[28px] font-normal dark:text-[#99ECF3]">CONTACT</h1>
              <h2 className="self-stretch text-[#001A4F] text-[40px] lg:text-[80px] font-TTBold leading-[80px] dark:text-[#99ECF3]">hello@biorce.com</h2>
              {/* <h3 className="self-stretch text-[#001A4F] text-[30px] lg:text-[40px] font-normal leading-[40px] mb-[20px] lg:mb-[50px] dark:text-[#99ECF3]">+ 34 600  333 555</h3> */}
              <div className="mt-[102px]"></div>
              <p className="text-[#001A4F] text-[16px] lg:text-[24px] font-normal leading-none uppercase dark:text-[#99ECF3]"> Norrsken House Barcelona </p>
              <p className="text-[#001A4F] text-[16px] lg:text-[24px] font-normal leading-none dark:text-[#99ECF3]">Passeig del Mare Nostrum 15</p>
              <p className="text-[#001A4F] text-[16px] lg:text-[24px] font-normal leading-none dark:text-[#99ECF3] ">Ciutat Vella, 08039 ,Barcelona</p>
            </div>

            <div className="w-[330px] md:w-1/2 lg:w-1/2 h-[300px] pl-0 md:pl-[65px] lg:pl-[130px] absolute md:relative lg:relative mt-[420px] md:mt-0 lg:mt-0">
              <form className="flex flex-col items-end gap-[22px]" onSubmit={handleSubmit}>
                
                <label htmlFor="fullName" className="sr-only">Full name</label>
                <input 
                  className={`w-full h-[44px] items-center self-stretch pl-[30px] rounded-lg border border-[#62BDBE] bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] dark:bg-[#161A1D] dark:via-[#161A1D] dark:placeholder-[#99ecf376] dark:hover:placeholder-[#99ECF3] text-[#001A4F] dark:text-[#99ECF3] hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.00)] ${namePlaceholder === 'Ex. John Doe' ? 'placeholder-gray-300' : 'placeholder-gray-400'}`}  
                  type="text"
                  placeholder={namePlaceholder}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  onFocus={() => setNamePlaceholder('Ex. John Doe')}
                  onBlur={() => setNamePlaceholder('Full name')}
                  required
                />
                
                <label htmlFor="email" className="sr-only">Email</label>
                <input 
                  className={`w-full h-[44px] items-center self-stretch pl-[30px] rounded-lg border border-[#62BDBE] bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] dark:bg-[#161A1D] dark:via-[#161A1D] dark:placeholder-[#99ecf376] dark:hover:placeholder-[#99ECF3] text-[#001A4F] dark:text-[#99ECF3] hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.00)] ${mailPlaceholder === 'Ex. name@email.com' ? 'placeholder-gray-300' : 'placeholder-gray-400'}`} 
                  type="email"
                  placeholder={mailPlaceholder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setMailPlaceholder('Ex. name@email.com')}
                  onBlur={() => setMailPlaceholder('Email')}
                  required
                />
                
                <label htmlFor="message" className="sr-only">Message</label>
                <textarea 
                  className="w-full h-[98px] items-center self-stretch pl-[30px] rounded-lg border border-[#62BDBE] bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] pt-2 dark:bg-[#161A1D] dark:via-[#161A1D] dark:placeholder-[#99ecf376] dark:hover:placeholder-[#99ECF3] text-[#001A4F] dark:text-[#99ECF3] hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.00)]" 
                  placeholder="Type your message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  >  
                </textarea>
                
                <button className="text-[#001A4F] w-full h-[44px] items-center self-stretch pl-[0] rounded-lg border border-[#62BDBE] bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] bg-[rgba(153,236,243,0.50)] hover:bg-[rgba(153,236,243,0.7)] dark:via-transparent" 
                type="submit"
                >{messageButton}</button>

              </form>
            </div>
          </div>

          <div className="h-[348px] items-center shrink-0 w-full mb-[450px] md:mb-[100px] lg:mb-40 relative overflow-hidden">
            <div className="contactVideoGradient w-[200px] lg:w-1/3 h-full absolute content-center z-10">
            </div>
            <div className="w-1/3 h-full absolute content-center pl-10 lg:pl-[85px] z-20">
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-normal w-1/2 leading-[50px] lg:leading-[75px]">Here</h3>
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-TTBold w-[200px] lg:w-[400px] leading-[50px] lg:leading-[75px]">We are</h3>
            </div>
            <video className="w-full h-full object-cover" src="norrsken.mp4" playsInline autoPlay loop muted></video>     
          </div>
        </div>
      </main>
    </div>
  )
}

export default Contact