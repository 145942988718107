import { Link, Element } from 'react-scroll'
import SectionTitle from "../../components/sectionTitle/SectionTitle"
import SeoComponent from '../../components/SEO-Component/SeoComponent'


const PrivacyPolicy = () => {
  return (
    <div>

        <SeoComponent page="privacyPolicy"/>

        <main>
            <div className="flex flex-col justify-center items-center mx-10 lg:mx-20 mb-20 max-w-7xl xl:mx-auto">

            <h1 className="text-[#001A4F] dark:text-[#99ECF3] text-[25px] md:text-[50px] lg:text-[59px] mt-[50px] md:mt-[150px] mb-[30px] font-TTBold">Privacy Statement</h1>

            <div className="flex flex-col gap-[20px] md:gap-[30px] w-full text-start mb-[50px]">
                <Link 
                smooth={true} 
                duration={800} 
                offset={-100}
                to="1Priv" 
                className="text-[#001A4F80] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] hover:text-[#001a4f] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">1. Introduction </Link>
                <Link 
                smooth={true} 
                duration={800} 
                offset={-100}
                to="2Priv" 
                className="text-[#001A4F80]  hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5  cursor-pointer">2. Personal Data  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="3Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">3. Derivative Data  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="4Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">4. Financial Data  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="5Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">5. Data from Social Networks  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="6Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">6. Mobile Device Data  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="7Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">7. Third-Party Data  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="8Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">8. GDPR  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="9Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">9. Data Security  </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="10Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">10. Use of Your Information </Link>
                <Link
                smooth={true} 
                duration={800} 
                offset={-100} 
                to="11Priv" 
                className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">11. Contact Us </Link>
            </div>

            <SectionTitle 
                    title= "Privacy statement" 
                    icon= {
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path className='dark:stroke-[#99ECF3]' d="M8.5 5H9.5M8.5 7H9.5M4.5 9H9.5M4.5 11H9.5M11.5 5H13.75C14.1642 5 14.5 5.33579 14.5 5.75V12C14.5 12.8284 13.8284 13.5 13 13.5M11.5 5V12C11.5 12.8284 12.1716 13.5 13 13.5M11.5 5V3.25C11.5 2.83579 11.1642 2.5 10.75 2.5H3.25C2.83579 2.5 2.5 2.83579 2.5 3.25V12C2.5 12.8284 3.17157 13.5 4 13.5H13M4.5 5H6.5V7H4.5V5Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    }
                />

            <div className="flex flex-col gap-[50px] w-full text-start">
                <Element className="flex flex-col w-full" name="1Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">1. Introduction </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Biorce ("we", "us", or "our") respects the privacy of our users ("user" or "you"). This Privacy Statement explains how we collect, use, disclose, and safeguard your information when you register or visit our website www.biorce.com, including any other media form, media channel, mobile website, or mobile application related or connected there to (collectively, the "Site"). Please read this privacy statement carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY STATEMENT, PLEASE DO NOT REGISTER OR ACCESS THE SITE.<br/> 
                    We reserve the right to make changes to this Privacy Statement at any time and for any reason. We will alert you about any changes by updating the "Effective Date" of this Privacy Statement. Any changes or modifications will be effective immediately upon posting the updated Privacy Statement on the Site, and you waive the right to receive specific notice of each such change or modification. <br/>
                    You are encouraged to periodically review this Privacy Statement to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Statement by your continued use of the Site after the date such revised Privacy Statement is posted.  </p>
                </Element>

                <Element className="flex flex-col w-full" name="2Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">2. Personal Data  </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Personally identifiable information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various services provided by us, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, but your refusal to do so may prevent you from using certain features of the Site..  </p>
                </Element>

                <Element className="flex flex-col w-full" name="3Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">3. Derivative Data </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.  </p>
                </Element>

                <Element className="flex flex-col w-full" name="4Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">4. Financial Data </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase our services, or request information about our services from the Site.  </p>
                </Element>

                <Element className="flex flex-col w-full" name="5Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">5. Data from Social Networks </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">User information from social networking sites, such as LinkedIn, Facebook, Google+, etc., including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks..  </p>
                </Element>

                <Element className="flex flex-col w-full" name="6Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">6. Mobile Device Data </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.  </p>
                </Element>

                <Element className="flex flex-col w-full" name="7Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">7. Third-Party Data </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information. </p>
                </Element>

                <Element className="flex flex-col w-full" name="8Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">8. GDPR </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Biorce is committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection. We have developed a robust data protection program that complies with existing law and abides by the data protection principles outlined in the GDPR. </p>
                </Element>

                <Element className="flex flex-col w-full" name="9Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">9. Data Security </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.  </p>
                </Element>

                <Element className="flex flex-col w-full" name="10Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">10. Use of Your Information </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Having accurate information about you permits us to provide our services to you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:<br/><br/>
                    • Create and manage your account.<br/>
                    • Process your transactions.<br/>
                    • Compile anonymous statistical data and analysis for use internally.<br/>
                    • Create targeted advertising, coupons, newsletters, and other information regarding promotions and the Site.<br/>
                    • Email you regarding your account or order.<br/>
                    • Enable user-to-user communications.<br/>
                    • Fulfill and manage purchases, orders, payments, and other transactions related to the Site.<br/>
                    • Generate a personal profile about you to make future visits to the Site more personalized.<br/>
                    • Increase the efficiency and operation of the Site.<br/>
                    • Monitor and analyze usage and trends to improve your experience with the Site.<br/>
                    • Notify you of updates to the Site.<br/>
                    • Offer new products, services, and/or recommendations to you.<br/>
                    • Perform other business activities as needed.<br/>
                    • Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.<br/>
                    • Process payments and refunds.<br/>
                    • Request feedback and contact you about your use of our services. </p>
                </Element>

                <Element className="flex flex-col w-full" name="11Priv">
                    <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">11. Contact Us </h2>
                    <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">If you have any questions about this Privacy Statement, please contact us at info@biorce.com  </p>
                </Element>

            </div>
            </div>
        </main>
    </div>
  )
}

export default PrivacyPolicy
