import { useEffect, useRef } from 'react';
import "./WhatWeAreAnimation.css"

const useAnimation = (element:any, delay:any) => {
        let timeoutId:any = null;
      
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
              }
              timeoutId = setTimeout(() => {
                element.classList.add('show');
              }, delay * 1000);
            } else {
              if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
              }
              //element.classList.remove('show');
            }
          },
          {
            threshold: 0.1,
          }
        );
      
        observer.observe(element);
      
        return () => {
          observer.unobserve(element);
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
        };
      };
      
      const WhatWeAreAnimation = () => {
        const delay1 = 1500; 
        const delay2 = 1200;
        const delay3 = 1800;
        const delay4 = 2100;
        const delay5 = 2700;
        const delay6 = 3300;
        const delay7 = 2400;
        const delay8 = 3000;

      
        const ref1 = useRef(null);
        const ref2 = useRef(null);
        const ref3 = useRef(null);
        const ref4 = useRef(null);
        const ref5 = useRef(null);
        const ref6 = useRef(null);
        const ref7 = useRef(null);
        const ref8 = useRef(null);

      
        useEffect(() => {
          const cleanup1 = ref1.current ? useAnimation(ref1.current, delay1 / 1000) : undefined;
          const cleanup2 = ref2.current ? useAnimation(ref2.current, delay2 / 1000) : undefined;
          const cleanup3 = ref3.current ? useAnimation(ref3.current, delay3 / 1000) : undefined;
          const cleanup4 = ref4.current ? useAnimation(ref4.current, delay4 / 1000) : undefined;
          const cleanup5 = ref5.current ? useAnimation(ref5.current, delay5 / 1000) : undefined;
          const cleanup6 = ref6.current ? useAnimation(ref6.current, delay6 / 1000) : undefined;
          const cleanup7 = ref7.current ? useAnimation(ref7.current, delay7 / 1000) : undefined;
          const cleanup8 = ref8.current ? useAnimation(ref8.current, delay8 / 1000) : undefined;

      
          return () => {
            if (cleanup1) cleanup1();
            if (cleanup2) cleanup2();
            if (cleanup3) cleanup3();
            if (cleanup4) cleanup4();
            if (cleanup5) cleanup5();
            if (cleanup6) cleanup6();
            if (cleanup7) cleanup7();
            if (cleanup8) cleanup8();

          };
        }, [delay1, delay2, delay3, delay4, delay5, delay6]);

  return (
    <div className='w-full max-w-7xl mx-auto'>
        <div className="parent mx-[8%] sm:mx-[25%] md:scale-[0.55] md:ml-[-90px] lg:mx-auto xl:scale-100 w-2/3 xl:max-w-7xl ">
            <h2 ref={ref1} className="vision-title div1 dark:text-[#99ECF3]">Innovators</h2>
            <h2 ref={ref2} className="vision-title div2 dark:text-[#99ECF3]">United</h2>
            <h2 ref={ref3} className="vision-title div3 dark:text-[#99ECF3]">Change-makers</h2>
            <h2 ref={ref4} className="vision-title div4 dark:text-[#99ECF3]">Empathetic</h2>
            <h2 ref={ref5} className="vision-title div5 dark:text-[#99ECF3]">Collaborative</h2>
            <h2 ref={ref6} className="vision-title div6 dark:text-[#99ECF3]">Impactful</h2>
            <h2 ref={ref7} className="vision-title div7 dark:text-[#99ECF3]">Purpose</h2>
            <h2 ref={ref8} className="vision-title div8 dark:text-[#99ECF3]">Progressive</h2>
        </div>

        <div className="mx-10 sm:mx-20 md:mx-0 lg:mx-20 pt-[135px] pb-[580px] md:pb-[300px] lg:pb-[300px] max-w-7xl xl:mx-auto">
          <h2 className="text-[#001A4F] text-center leading-trim text-capitals font-semibold text-[40px] lg:text-[120px] dark:text-[#99ECF3]">WHAT WE ARE</h2>
        </div>
    </div>
  )
}

export default WhatWeAreAnimation
