import { useEffect, useRef } from 'react';
import "./TextBlocksAnimation.css";

const applyIntersectionObserver = (element:any, delay:any) => {
  let timeoutId:any = null;

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        timeoutId = setTimeout(() => {
          element.classList.add('show');
        }, delay * 1000);
      } else {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        //element.classList.remove('show');
      }
    },
    {
      threshold: 0.1,
    }
  );

  observer.observe(element);

  return () => {
    observer.unobserve(element);
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
};

const TextBlocksAnimation = () => {
  const delay1 = 1000; 
  const delay2 = 1000;
  const delay3 = 1200;
  const delay4 = 1400;
  const delay5 = 1600;
  const delay6 = 2000;

  const visionRef = useRef(null);
  const toSaveRef = useRef(null);
  const oneRef = useRef(null);
  const byRef = useRef(null);
  const one2Ref = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const cleanup1 = visionRef.current ? applyIntersectionObserver(visionRef.current, delay1 / 1000) : undefined;
    const cleanup2 = toSaveRef.current ? applyIntersectionObserver(toSaveRef.current, delay2 / 1000) : undefined;
    const cleanup3 = oneRef.current ? applyIntersectionObserver(oneRef.current, delay3 / 1000) : undefined;
    const cleanup4 = byRef.current ? applyIntersectionObserver(byRef.current, delay4 / 1000) : undefined;
    const cleanup5 = one2Ref.current ? applyIntersectionObserver(one2Ref.current, delay5 / 1000) : undefined;
    const cleanup6 = textRef.current ? applyIntersectionObserver(textRef.current, delay6 / 1000) : undefined;

    return () => {
      if (cleanup1) cleanup1();
      if (cleanup2) cleanup2();
      if (cleanup3) cleanup3();
      if (cleanup4) cleanup4();
      if (cleanup5) cleanup5();
      if (cleanup6) cleanup6();
    };
  }, [delay1, delay2, delay3, delay4, delay5, delay6]);

  return (
    <div className="mb-[100px] lg:mb-[300px]">
      <h3 ref={visionRef} className="vision-title max-w-7xl mx-10 lg:mx-20 xl:mx-auto text-[#001A4F] leading-[40px] text-[16px] lg:text-[28px] font-normal uppercase dark:text-[#99ECF3]">
        Our vision
      </h3>
      <div className="mx-10 lg:mx-20 xl:mx-auto block lg:flex text-left max-w-7xl">
        <div className="lg:w-1/2 lg:h-[220px]">
          <h3 ref={toSaveRef} className="vision-title text-[#001A4F] leading-[40px] lg:leading-[70px] text-[40px] lg:text-[80px] font-normal dark:text-[#99ECF3]">To save <br/>patients lives,</h3>
          <div className='flex'>
            <h3 ref={oneRef} className="vision-title text-[#001A4F] leading-[40px] lg:leading-[70px] text-[40px] lg:text-[80px] font-normal dark:text-[#99ECF3]"><strong className='font-TTBold'>one</strong></h3>
            <h3 ref={byRef} className="vision-title text-[#001A4F] leading-[40px] lg:leading-[70px] text-[40px] lg:text-[80px] font-normal ml-5 dark:text-[#99ECF3]"><strong className='font-TTBold'>by</strong></h3>
            <h3 ref={one2Ref} className="vision-title text-[#001A4F] leading-[40px] lg:leading-[70px] text-[40px] lg:text-[80px] font-normal ml-5 dark:text-[#99ECF3]"><strong className='font-TTBold'>one.</strong></h3>
          </div>
        </div>
        <div className="lg:w-1/2 lg:h-[220px]">
          <p ref={textRef} className="vision-title text-[#001A4F] leading-[20px] mt-4 lg:mt-0 lg:leading-[1.1] text-[18px] lg:text-[32px] font-normal dark:text-[#99ECF3]">
            At Biorce, we believe we will have a key role in supporting patients. It all started with a why, our why was a very special patient. A patient who needed help and we are now here with this ambition so that others will no longer need that help.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextBlocksAnimation;
