import { Helmet } from "react-helmet"

const SeoComponent = ({ page }:any) => {
  let h1:string = ""
  let title:string = ""
  let description:string = ""
  let keywords:string = ""
  let ogTitle:string = ""
  let ogDescription:string = ""
  let ogUrl:string = "" 
  let ogImage:string = ""
  let twitterTitle:string = ""
  let twitterDescription:string = ""
  let twitterImage:string = ""
  
  if (page === "about"){
    h1 = "About"
    title = "Biorce - About"
    description = "Discover Biorce's mission and vision as we innovate in healthcare. Learn about our dedicated team, exciting partnerships, and our commitment to improving clinical trials. Join us in making a difference!"
    keywords = "Biorce, innovación en ensayos clínicos, tecnología en salud, IA en investigación clínica, transformación digital en salud, equipo Biorce, seguridad de datos médicos, colaboración en investigación médica, mejora de ensayos clínicos, oportunidades de carrera en salud, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - About Us"
    ogDescription = "Discover Biorce's mission in healthcare and meet our innovative team dedicated to transforming clinical trials." 
    ogUrl = "/about" 
    ogImage = "/web-about.png"
    twitterTitle = "Biorce - About Us"
    twitterDescription = "Learn about our mission, vision, and the team behind Biorce as we innovate in healthcare."
    twitterImage = "/web-about.png"

  } else if (page === "careers"){
    h1 = "Careers"
    title = "Biorce - Careers"
    description = "Explore career opportunities at Biorce. Join our innovative team and contribute to advancing healthcare."
    keywords = "Biorce, careers, job opportunities, health technology, healthcare innovation, clinical research, artificial intelligence, life sciences, career development, AI in healthcare, patient care, research collaboration, job openings, work with us, Biorce team, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Careers"
    ogDescription = "Discover career opportunities at Biorce and meet our innovative team dedicated to transforming clinical trials." 
    ogUrl = "/careers" 
    ogImage = "/web-careers.png"
    twitterTitle = "Biorce - Careers"
    twitterDescription = "Join Biorce and be part of our team innovating in the healthcare sector."
    twitterImage = "/web-careers.png"

  } else if (page === "contact"){
    h1 = "Biorce - Contact"
    title = "Biorce - Contact"
    description = "Get in touch with Biorce. We're here to answer your questions and explore opportunities together. Fill out the form to send us a message, and we'll get back to you."
    keywords = "Biorce, contact us, healthcare technology, healthcare innovation, clinical research, AI in healthcare, life sciences, healthcare careers, job opportunities, patient support, research collaboration, career development, send us a message, get in touch, customer inquiries, healthcare solutions, medical research, contact information, Biorce team, Norrsken House Barcelona, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Contact"
    ogDescription = "Connect with Biorce and discover how we can innovate healthcare together. Our dedicated team is here to assist you." 
    ogUrl = "/contact" 
    ogImage = "/web-contact.png"
    twitterTitle = "Biorce - Contact"
    twitterDescription = "Reach out to Biorce for inquiries and to learn how we are transforming healthcare through innovation."
    twitterImage = "/web-contact.png"

  } else if (page === "faqs"){
    h1 = "Biorce - faqs"
    title = "Biorce - faqs"
    description = "Discover answers to common questions about Biorce and our AI clinical trials assistant, Jarvis. Learn how we streamline the research process and enhance healthcare outcomes."
    keywords = "Biorce, FAQs, frequently asked questions, Jarvis, AI clinical trials, healthcare technology, data security, patient recruitment, clinical research assistant, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - FAQs"
    ogDescription = "Learn about Biorce's innovative healthcare solutions and get answers to frequently asked questions about our AI clinical trials assistant, Jarvis." 
    ogUrl = "/faqs" 
    ogImage = "/web-faqs.png"
    twitterTitle = "Biorce - FAQs"
    twitterDescription = "Get answers to common questions about Biorce and our AI assistant Jarvis, designed to enhance clinical trials and healthcare outcomes."
    twitterImage = "/web-faqs.png"

  } else if (page === "home"){
    h1 = "Biorce - Home"
    title = "Biorce - Home"
    description = "Welcome to Biorce! Explore our mission, discover Jarvis, and learn about our partnerships. Join our newsletter to stay updated on our latest innovations and insights. Experience how we strive to make a difference."
    keywords = "Biorce, healthcare technology, healthcare innovation, clinical research, artificial intelligence in healthcare, life sciences solutions, patient support services, healthcare careers, job opportunities in healthcare, research collaboration opportunities, career development in life sciences, customer inquiries, healthcare solutions provider, medical research advancements, Biorce team contact, Norrsken House Barcelona, join our newsletter, latest healthcare innovations, transformative healthcare solutions, patient-centric technologies, AI healthcare applications, cutting-edge clinical research, subscribe for updates, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Home"
    ogDescription = "Welcome to Biorce. Explore our mission and discover how we make a difference in the healthcare sector." 
    ogUrl = "/home" 
    ogImage = "/web-home.png"
    twitterTitle = "Biorce - Home"
    twitterDescription = "Welcome to Biorce. Explore our mission and discover how we make a difference in the healthcare sector."
    twitterImage = "/web-home.png"

  } else if (page === "jarvis"){
    h1 = "Biorce - Jarvis"
    title = "Biorce - Jarvis"
    description = "Meet Jarvis, your innovative AI companion designed for clinical trials. Explore Biorce's mission, strategic partnerships, and groundbreaking technologies that enhance the clinical trial experience. Sign up for our newsletter to stay updated on the latest healthcare innovations and learn how Jarvis is revolutionizing patient and healthcare professional interactions."
    keywords = "Jarvis AI companion, clinical trial assistant, Biorce healthcare technology, innovative healthcare solutions, patient engagement in clinical trials, artificial intelligence healthcare applications, life sciences advancements, patient support tools, healthcare career opportunities, clinical research collaborations, healthcare innovation news, subscribe for healthcare updates, transformative patient-centric technologies, AI-driven clinical research, clinical trial optimization, healthcare professionals tools, patient journey support, AI in healthcare research, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Jarvis"
    ogDescription = "Meet Jarvis, your innovative AI companion designed for clinical trials. Explore Biorce's mission, strategic partnerships, and groundbreaking technologies that enhance the clinical trial experience." 
    ogUrl = "/jarvis" 
    ogImage = "/web-jarvis.png"
    twitterTitle = "Biorce - Jarvis"
    twitterDescription = "Discover how Jarvis, Biorce's AI assistant, is transforming clinical trials and improving healthcare outcomes."
    twitterImage = "/web-jarvis.png"

  } else if (page === "privacyPolicy"){
    h1 = "Biorce - Privacy Policy"
    title = "Biorce - Privacy Policy"
    description = "Read our Privacy Policy to understand how Biorce collects, uses, and protects your personal information. Discover our commitments to data security and your rights under GDPR."
    keywords = "Biorce privacy policy, personal data protection, data security measures, user privacy rights, GDPR compliance, data collection practices, healthcare technology privacy, patient data privacy, artificial intelligence in healthcare, personal information management, privacy rights under GDPR, online privacy statement, healthcare data practices, user consent and data usage, social media data collection, mobile device privacy, third-party data sharing, financial data security, data anonymization, user data security, contact Biorce for privacy inquiries, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Privacy Policy"
    ogDescription = "Read our Privacy Policy to understand how Biorce collects, uses, and protects your personal information. Learn more about GDPR compliance and data security." 
    ogUrl = "/privacy-policy" 
    ogImage = "/privacy-policy-image.png"
    twitterTitle = "Biorce - Privacy Policy"
    twitterDescription = "Read our Privacy Policy to understand how Biorce collects, uses, and protects your personal information."
    twitterImage = "/privacy-policy-image.png"

  } else if (page === "TermsOfUse"){
    h1 = "Biorce - Terms of use"
    title = "Biorce - Terms of use"
    description = "Review the Terms of Use for Biorce to understand your rights and responsibilities when using our services. Learn about our policies, user obligations, and the legal framework governing your use of our site."
    keywords = "Biorce Terms of Use, user agreement, terms and conditions, service agreement, user responsibilities, legal rights, online service terms, clinical trial services, artificial intelligence in healthcare, data protection policies, user consent, data usage policies, intellectual property rights, user contributions, prohibited activities, compliance with regulations, liability disclaimer, indemnification, governing law, healthcare technology terms, GDPR compliance, user privacy protection, data security practices, healthcare data management, service access guidelines, rights and obligations of users, Jarvis clinical trial assistant, AI healthcare solutions with Jarvis, Jarvis for clinical trial optimization, how to improve clinical trial processes with AI, AI solutions for patient recruitment in clinical trials, healthcare technology for improving patient outcomes, digital transformation in clinical research, future of healthcare innovation, transforming healthcare with AI, AI-powered drug development, subscribe for healthcare updates, sign up for healthcare innovation news, contact us for clinical trial solutions, join our healthcare innovation team"
    ogTitle = "Biorce - Terms of Use"
    ogDescription = "Review the Terms of Use for Biorce to understand your rights and responsibilities when using our services." 
    ogUrl = "/terms-of-use" 
    ogImage = "/terms-of-use-image.png"
    twitterTitle = "Biorce - Terms of Use"
    twitterDescription = "Review the Terms of Use for Biorce to understand your rights and responsibilities when using our services."
    twitterImage = "/terms-of-use-image.png"

  } 

  return (
    <div>
      <h1 className="hidden">Biorce - {h1}</h1>
      <Helmet>
          <title>{title}</title>
          <meta name="description" content = {description}/>
          <meta name="keywords" content = {keywords} />
          <meta name="author" content="Biorce Team"/>

          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:url" content={`${String(import.meta.env.VITE_MS_TEST_BIORCE_URL)}${ogUrl}`} /> 
          <meta property="og:image" content={`${String(import.meta.env.VITE_MS_TEST_BIORCE_URL)}${ogImage}`} />
          <meta property="og:type" content="website" /> 
          <meta property="og:site_name" content="Biorce" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content= {twitterTitle} />
          <meta name="twitter:description" content= {twitterDescription} />
          <meta name="twitter:image" content={`${String(import.meta.env.VITE_MS_TEST_BIORCE_URL)}${twitterImage}`}/>
      </Helmet>
    </div>
  )
}

export default SeoComponent
