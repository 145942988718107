import { useState } from 'react'
import SectionTitle from "../../components/sectionTitle/SectionTitle"
import app from "../../firebaseConfig"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import SeoComponent from '../../components/SEO-Component/SeoComponent'
import { Link } from 'react-router-dom'

const Careers = () => {
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)
  const [messageButton, setMessageButton] = useState('Send')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedCarrer, setSelectedCarrer] = useState('')
  const [selectedCarrerName, setSelectedCarrerName] = useState('View positions')
  const [fileName, setFileName] = useState('Attach CV')
  const [selectedFile, setSelectedFile] = useState(null)
  let fileDownloadURL = null
  const storage = getStorage(app)
  const [namePlaceholder, setNamePlaceholder] = useState('Full Name')
  const [mailPlaceholder, setMailPlaceholder] = useState('Email')
  const [isClicked, setIsClicked] = useState(false)
  const [isClickedPrivacyPolicy, setIsClickedPrivacyPolicy] = useState(false)


  const handleClick = () => {
     setIsClicked(!isClicked)
  }

  const handleSelectChange = (event:any) => {
    setSelectedCarrer(event.target.value)
    setSelectedCarrerName(event.target.value)
    setIsOpen(false)
  }
    
  const handleFileChange = (event:any) => {
    setSelectedFile (event.target.files[0])
    setFileName(event.target.files[0].name)
    const file = event.target.files[0]
    if (file) {
      if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
        setSelectedFile(null)
        alert("The file size exceeds 2MB. Please upload a smaller file.")
        setFileName('Attach CV')
      } 
    } else {
      setFileName('Attach CV')
      setSelectedFile (null)
    }
  }
  
  const uploadFileToDrive = async (): Promise<string | false> => {
    if (!selectedFile) {
      console.error("No file selected.")
      return false
    }

    if(selectedCarrerName === 'View positions' || selectedCarrerName === "Department"){
      setMessageButton("Please fill out the form.")
      return false
    }else{
      try {
        const storageRef = ref(storage, `${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setFileName('Upload is ' + progress + '% done')
            },
            (error) => {
              console.error('Error uploading file:', error)
              reject(false)
            },
            async () => {
              const downloadURL = await getFileUrl(fileName) 
              setSelectedFile (null)
              setFileName('File uploaded')
              resolve(downloadURL)
            }    
          )
        })
      } catch (error) {
        console.error('Error uploading file:', error)
        setMessageButton("Error")
        return false
      }
    }
  }

  const getFileUrl = async (fileName:any): Promise<string> => {
    try {
      const storageRef = ref(storage, fileName)
      const downloadURL = await getDownloadURL(storageRef)
      return downloadURL
  
    } catch (error) {
      console.error("Error al obtener el archivo:", error)
      throw error
    }
  }


  const handleSubmit = async (event:any) => {
    event.preventDefault()
    handleClick()

    const [firstname, ...lastnameArray] = fullName.split(" ")
    const lastname = lastnameArray.join(" ")
    fileDownloadURL = await uploadFileToDrive()

    const requestBody = {
       fields: [
         {
           objectTypeId: "0-1",
           name: "jobs_positions",
           value: selectedCarrer
         },
         {
           objectTypeId: "0-1",
           name: "firstname",
          value: firstname
         },
         {
           objectTypeId: "0-1",
           name: "lastname",
           value: lastname
         },
         {
           objectTypeId: "0-1",
           name: "email",
           value: email
         },
         {
          objectTypeId: "0-1",
          name: "curriculum_vitae",
          value: fileDownloadURL
        }      
       ],
     }

     console.log("requestBody", requestBody)

    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${import.meta.env.VITE_PORTAL_ID}/${import.meta.env.VITE_FORM_GUID3}` 

    if (fileDownloadURL !== ""){
      if(selectedCarrerName === 'View positions' || selectedCarrerName === "Department" ){
        setMessageButton("Please select a Department")
        setIsClicked(!isClicked)
      } else if(fullName === ""){
        setMessageButton("Please select full name.")    
        setIsClicked(!isClicked)
      } else if(email === ""){
        setMessageButton("Please select Email.")
        setIsClicked(!isClicked)
      }else if(fileDownloadURL === ""){
        setMessageButton("Please select file.")
        setIsClicked(!isClicked)
      }else{
        try {
          const response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          })
          if (response.ok) {
            const result = await response.json()
            setMessageButton(result.inlineMessage)
            setSelectedCarrerName ('View positions')
            setFullName("")
            setEmail("")
            setFileName('Attach CV')
            setSelectedFile(null)
            setIsClicked(!isClicked)
          } else {  
            setMessageButton("Something went wrong, please try again.")
            setIsClicked(!isClicked)
          } 
        } catch (error) {  
          setMessageButton("Something went wrong, please try again.")
          setIsClicked(!isClicked)
        }    
      }
  }else{
    setMessageButton("Please select a file and try again.")
    setIsClicked(!isClicked)
  }
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    setSelectedCarrerName("Department")
  }

  const handlePrivacyPolicyClick = () => {
    setIsLogoutModalVisible(true)
    document.body.style.overflow = 'hidden'
  }

  const handleCloseModal = () => {
    setIsLogoutModalVisible(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <div>

      <SeoComponent page="careers"/>

      <main>
        <div className="mx-10 lg:mx-20 2xl:max-w-7xl 2xl:mx-auto">
          
          <h1 className="text-[#001A4F] text-[16px] lg:text-[28px] font-normal leading-[20px] lg:leading-[70px] mt-[20px] lg:mt-[150px] dark:text-[#99ECF3]">
            WHY AT BIORCE?
          </h1>  
            
          <h2 className="text-[#001A4F] text-[40px] lg:text-[80px] font-normal leading-[40px] lg:leading-[75px] mb-[20px] lg:mb-[50px] dark:text-[#99ECF3] hidden lg:block">
            <strong className='font-TTBold'>Join Biorce</strong> and be a part <br/>of something bigger.
          </h2> 

          <h2 className="text-[#001A4F] text-[38px] lg:text-[80px] font-normal leading-[40px] lg:leading-[75px] mb-[20px] lg:mb-[50px] dark:text-[#99ECF3] lg:hidden">
            <strong>Join Biorce</strong> and be <br/> a part of something <br/> bigger.
          </h2> 
            
          <p className="text-[#001A4F] text-[18px] lg:text-[38px] font-normal leading-[20px] lg:leading-[40px] mb-[50px] lg:mb-[250px] dark:text-[#99ECF3]">We offer a dynamic and collaborative environment where you can make a real difference in the lives of patients around the world. Work alongside talented minds, push the boundaries of AI in healthcare, and <strong className='font-TTBold'>contribute to a future</strong> where groundbreaking treatments become a reality. At Biorce, your passion becomes purpose.
          </p>
        </div>

          <div className="h-[300px] lg:h-[500px] items-center shrink-0 w-full mb-[150px] lg:mb-[250px] relative overflow-hidden 2xl:max-w-7xl 2xl:mx-auto">
            <div className="contactVideoGradient w-1/2 lg:w-[628px] h-full absolute content-center z-10">
            </div>
            <div className="w-1/3 h-full absolute content-center pl-10 lg:pl-20 z-20">
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-normal w-[200px] lg:w-[400px] leading-[50px] lg:leading-[75px] hidden lg:flex"><strong className='font-TTBold'>Be part </strong> &nbsp;of</h3>
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-normal w-[200px] lg:w-full leading-[50px] lg:leading-[75px] flex lg:hidden"><strong className='font-TTBold'>Be part</strong></h3>
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-normal w-[200px] leading-[50px] lg:leading-[75px] flex lg:hidden">of Biorce</h3>
              <h3 className="text-[#A5F6FF] text-[40px] lg:text-[80px] font-normal w-2/3 leading-[50px] lg:leading-[75px] hidden lg:block">Biorce</h3>
            </div>
            <video className="w-full h-full object-cover" src="/bePartOf2.mp4" playsInline autoPlay loop muted></video>     
          </div>

        <div className="mx-10 lg:mx-20">

          <SectionTitle 
            title= "Apply Now" 
            icon= {
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                <path className='dark:stroke-[#99ECF3]' d="M4.39484 7.21291C3.56416 6.76117 3.00033 5.88076 3.00033 4.86867C3.00033 3.85657 3.56417 2.97615 4.39487 2.52441M3.0199 10.4801C2.21627 10.8958 1.66699 11.7348 1.66699 12.702C1.66699 13.393 2.13424 13.9749 2.77003 14.1489M15.2306 14.1489C15.8664 13.9749 16.3337 13.393 16.3337 12.702C16.3337 11.7348 15.7844 10.8959 14.9807 10.4801M13.6058 7.21291C14.4365 6.76117 15.0003 5.88076 15.0003 4.86867C15.0003 3.85656 14.4365 2.97616 13.6058 2.52442M11.667 4.86882C11.667 6.34157 10.4731 7.53548 9.00033 7.53548C7.52757 7.53548 6.33366 6.34157 6.33366 4.86882C6.33366 3.39606 7.52757 2.20215 9.00033 2.20215C10.4731 2.20215 11.667 3.39606 11.667 4.86882ZM6.50033 14.2021H11.5003C12.3288 14.2021 13.0003 13.5306 13.0003 12.7021C13.0003 11.3214 11.881 10.2021 10.5003 10.2021H7.50033C6.11961 10.2021 5.00033 11.3214 5.00033 12.7021C5.00033 13.5306 5.6719 14.2021 6.50033 14.2021Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            }
          />

          <div className="block md:flex lg:flex mb-[30px] lg:mb-[100px] 2xl:max-w-7xl 2xl:mx-auto">
            
            <div className="w-[330px] md:w-1/2 lg:w-1/2 mt-[40px] lg:mt-0">
              <h3 className="text-[#001A4F] text-[40px] lg:text-[80px] md:ml-3 lg:ml-0 font-normal leading-[40px] lg:leading-[75px] dark:text-[#99ECF3]"><strong className='font-TTBold'>Join us</strong> and revolutionise the life sciences industry with AI.</h3>
            </div>

            <form className="w-full md:w-1/2 lg:w-1/2 flex flex-col items-end gap-[10px] lg:gap-[22px] mt-[40px] lg:mt-0 lg:pl-40" onSubmit={!isClickedPrivacyPolicy ? handlePrivacyPolicyClick : handleSubmit}>

              <button
                className="custom-select w-full h-[44px] items-center self-stretch px-[30px] rounded-lg border border-[#62BDBE] 
                  bg-gradient-to-r
                from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] backdrop-blur-[17.5px]
                dark:bg-[#161A1D] dark:via-[#161A1D]              
                dark:placeholder-[#99ECF3] dark:text-[#99ECF3] flex
                dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500
                hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg]] hover:bg-[rgba(254,254,254,0.00)]"
                type="button"
                onClick={toggleAccordion}
              >
                {selectedCarrerName}
                {!isOpen ? <svg className='absolute right-[10px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M6.5 9.20215C8.07701 11.3829 9.92293 13.3386 11.9899 15.0193C12.2897 15.2631 12.7103 15.2631 13.0101 15.0193C15.0771 13.3386 16.923 11.3829 18.5 9.20215" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> : <svg className='absolute right-[10px]' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M18 15C16.423 12.8192 14.5771 10.8636 12.5101 9.18284C12.2103 8.93905 11.7897 8.93905 11.4899 9.18284C9.42294 10.8636 7.57701 12.8192 6 15" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>}
                
              </button>
              {isOpen && (
                <div className="w-full mt-[-20px] border border-[#62BDBE] rounded-lg px-5 py-4 bg-white dark:bg-[#161A1D]">
                  <button className='flex w-full h-[44px] items-center px-[30px] rounded-lg border border-[#62BDBE] m-1 text-center justify-center dark:text-[#99ECF3] hover:bg-gradient-to-r hover:backdrop-blur-[17.5px] transition duration-300 ease-in-out dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.01)] 
                  ' onClick={() => handleSelectChange({ target: { value: 'development' } })}>Development</button>
                  <button className='flex w-full h-[44px] items-center px-[30px] rounded-lg border border-[#62BDBE] m-1 text-center justify-center dark:text-[#99ECF3] hover:bg-gradient-to-r hover:backdrop-blur-[17.5px] transition duration-300 ease-in-out dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.01)] 
                  ' onClick={() => handleSelectChange({ target: { value: 'design' } })}>Design</button>
                  <button className='flex w-full h-[44px] items-center px-[30px] rounded-lg border border-[#62BDBE] m-1 text-center justify-center dark:text-[#99ECF3] hover:bg-gradient-to-r hover:backdrop-blur-[17.5px] transition duration-300 ease-in-out dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.01)] 
                  ' onClick={() => handleSelectChange({ target: { value: 'scientific' } })}>Scientific</button>
                  <button className='flex w-full h-[44px] items-center px-[30px] rounded-lg border border-[#62BDBE] m-1 text-center justify-center dark:text-[#99ECF3] hover:bg-gradient-to-r hover:backdrop-blur-[17.5px] transition duration-300 ease-in-out dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.01)] 
                  ' onClick={() => handleSelectChange({ target: { value: 'commercial' } })}>Commercial</button>
                  <button className='flex w-full h-[44px] items-center px-[30px] rounded-lg border border-[#62BDBE] m-1 text-center justify-center dark:text-[#99ECF3] hover:bg-gradient-to-r hover:backdrop-blur-[17.5px] transition duration-300 ease-in-out dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg] hover:bg-[rgba(254,254,254,0.01)] 
                  ' onClick={() => handleSelectChange({ target: { value: 'other' } })}>Other</button>
                </div>
              )}              

              <input 
                className={`w-full h-[44px] items-center self-stretch pl-[30px] rounded-lg border border-[#62BDBE] dark:text-[#99ECF3]
                bg-transparent bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] dark:bg-[linear-gradient(114deg,rgba(94,186,186,0.15)_1.41%,rgba(94,186,186,0.00)_98.58%),linear-gradient(114deg,rgba(22,26,29,0.50)_0%,rgba(22,26,29,0.00)_100%)] dark:placeholder-[#99ecf376] dark:hover:placeholder-[#99ECF3] placeholder-gray-400 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg]] hover:bg-[rgba(254,254,254,0.00)] ${namePlaceholder === 'Ex. John Doe' ? 'placeholder-gray-300' : 'placeholder-gray-400'}`} 
                type="text"
                placeholder={namePlaceholder}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                onFocus={() => setNamePlaceholder('Ex. John Doe')}
                onBlur={() => setNamePlaceholder('Full name')}
                required
              />

              <input className={`w-full h-[44px] items-center self-stretch pl-[30px] rounded-lg border border-[#62BDBE] 
                bg-transparent bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] dark:bg-[linear-gradient(114deg,rgba(94,186,186,0.15)_1.41%,rgba(94,186,186,0.00)_98.58%),linear-gradient(114deg,rgba(22,26,29,0.50)_0%,rgba(22,26,29,0.00)_100%)] dark:placeholder-[#99ecf376] dark:hover:placeholder-[#99ECF3] dark:text-[#99ECF3] placeholder-gray-400 hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg]] hover:bg-[rgba(254,254,254,0.00)] ${mailPlaceholder === 'Ex. name@email.com' ? 'placeholder-gray-300' : 'placeholder-gray-400'}`} 
                type="email"
                placeholder={mailPlaceholder}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setMailPlaceholder('Ex. name@email.com')}
                onBlur={() => setMailPlaceholder('Email')}
                required
              />

              <label 
                className="w-full h-[44px] flex items-center pl-[30px] rounded-lg border border-[#62BDBE] 
                bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] dark:bg-[linear-gradient(114deg,rgba(94,186,186,0.15)_1.41%,rgba(94,186,186,0.00)_98.58%),linear-gradient(114deg,rgba(22,26,29,0.50)_0%,rgba(22,26,29,0.00)_100%)] dark:text-[#99ECF3] hover:bg-gradient-to-r hover:from-[rgba(94,186,186,0.2)] hover:to-[rgba(94,186,186,0.005)] hover:bg-gradient-[114deg]] hover:bg-[rgba(254,254,254,0.00)] transition duration-300 ease-in-out cursor-pointer dark:text-[#99ecf376] dark:hover:text-[#99ECF3] text-gray-400 hover:text-gray-500"
                htmlFor="cv-upload"
              >
                {fileName}

                {selectedFile === null ? <svg className='absolute right-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path 
                  className='dark:stroke-[#99ECF3]'
                  d="M18.5 9.20215L18.5 16.2021C18.5 19.5159 15.8137 22.2021 12.5 22.2021C9.18629 22.2021 6.5 19.5159 6.5 16.2021V6.20215C6.5 3.99301 8.29086 2.20215 10.5 2.20215C12.7091 2.20215 14.5 3.99301 14.5 6.20215L14.5 16.2021C14.5 17.3067 13.6046 18.2021 12.5 18.2021C11.3954 18.2021 10.5 17.3067 10.5 16.2021L10.5 7.20215" 
                  stroke="#001A4F" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round"/>
                </svg> : <svg className='absolute right-[10px]' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M14 2.05752V3.2C14 4.88016 14 5.72024 14.327 6.36197C14.6146 6.92646 15.0735 7.3854 15.638 7.67302C16.2798 8 17.1198 8 18.8 8H19.9425M14 2.05752C13.6065 2 13.136 2 12.349 2H10.4C8.15979 2 7.03968 2 6.18404 2.43597C5.43139 2.81947 4.81947 3.43139 4.43597 4.18404C4 5.03968 4 6.15979 4 8.4V15.6C4 17.8402 4 18.9603 4.43597 19.816C4.81947 20.5686 5.43139 21.1805 6.18404 21.564C7.03968 22 8.15979 22 10.4 22H13.6C15.8402 22 16.9603 22 17.816 21.564C18.5686 21.1805 19.1805 20.5686 19.564 19.816C20 18.9603 20 17.8402 20 15.6V9.65097C20 8.864 20 8.39354 19.9425 8M14 2.05752C14.0957 2.07151 14.1869 2.0889 14.2769 2.11052C14.6851 2.20851 15.0753 2.37012 15.4331 2.58944C15.8368 2.83681 16.1827 3.18271 16.8745 3.87451L18.1255 5.12548C18.8173 5.81724 19.1632 6.1632 19.4106 6.56686C19.6299 6.92475 19.7915 7.31493 19.8895 7.72307C19.9111 7.81313 19.9285 7.90429 19.9425 8M9 14.6662L11.3412 17.0049C12.4672 15.0359 14.0256 13.3483 15.8987 12.0692" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>}

              </label>
              
              <input
                className="hidden"
                placeholder="Attach CV" 
                type="file"
                id="cv-upload"
                onChange={handleFileChange}  
              />
              
              <button 
              onClick={!isClickedPrivacyPolicy ? handlePrivacyPolicyClick : handleSubmit}
              className={`text-[#001A4F] w-full h-[44px] items-center self-stretch  rounded-lg border border-[#62BDBE] ffv
                dark:hover:bg-[rgba(153,236,243,0.7)] 
                bg-gradient-to-r from-[rgba(94,186,186,0.03)] to-[rgba(94,186,186,0)] bg-gradient-[114deg] via-[rgba(254,254,254,0.50)] backdrop-blur-[17.5px] bg-[rgba(153,236,243,0.50)] hover:bg-[rgba(153,236,243,0.7)] dark:via-transparent
                
                ${isClicked ? 'bg-[#001A4F] dark:bg-[#99ECF3]' : 'bg-[rgba(0,26,79,0.50)] dark:bg-[rgba(153,236,243,0.50)]'} 
              text-[#001A4F]`} type={!isClickedPrivacyPolicy ? "button" : "submit"}>{messageButton}</button>
            
            </form>
          </div>   

          <h3 className="text-[#001A4F] text-[16px] lg:text-[28px] font-normal leading-[70px] uppercase dark:text-[#99ECF3] 2xl:max-w-7xl 2xl:mx-auto">WHY JOIN US?</h3>
          <p className="text-[#001A4F] text-[17.5px] lg:text-[28px] font-normal leading-[20px] lg:leading-[30px] mb-[100px] lg:mb-[250px] whitespace-pre-wrap dark:text-[#99ECF3] 2xl:max-w-7xl 2xl:mx-auto ">
            Let’s be honest, if you came to this page, <strong className='font-TTBold'>you know what you are looking for.</strong><br/>
            And most probably <strong className='font-TTBold'>we are looking for you.</strong><br/>
            What’s left? <strong className='font-TTBold'>Apply and let’s see if we are a match for each other.</strong><br/> 
            But don’t put your best foot forward, we want to see the genuine you - yes, skills matter, but leaders are also built through character & personality. Are you the next leader?
          </p>
        </div>
      </main>
     
      <div className={`z-10 absolute top-0 left-0 w-screen h-full bg-[rgba(22,26,29,0.30)] blur-[8-px] flex justify-center items-center ${isLogoutModalVisible && !isClickedPrivacyPolicy ? '' : 'hidden'}`}>
            <div className='fixed top-[60%] border border-[#62BDBE] bg-gradient-to-br from-[rgba(94,186,186,0.15)] to-[rgba(94,186,186,0)] backdrop-blur-[17.5px] flex flex-col w-[250px] md:w-[450px] items-center justify-center shadow-[0px_8px_24px_0px_rgba(34,39,43,0.12)] rounded-[18px] p-[20px] px-[37px] gap-[20px]'>

              <div className='flex items-start self-stretch'>
                <div className='w-full flex justify-center items-center'>
                  <Link 
                    to="/privacy-Policy"
                    target='blank'
                    className='text-[#22272B] dark:text-[#99ECF3] font-TTregular text-[15.692px] leading-[23.538px] flex justify-start items-center rounded-[1307.692px] border border-[rgba(94,186,186,0.5)]
                    
                    bg-[rgba(254, 254, 254, 0.80)]
                    dark:bg-[rgba(34,39,43,0.8)] bg-gradient-to-b from-[rgba(165,246,255,0.08)] to-[rgba(165,246,255,0.08)] backdrop-blur-[6.538px] px-[10.462px] py-[5.231px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                    <path className="dark:stroke-[#99ECF3]" d="M8.88528 10.5165L10.6347 12.2641C11.4926 10.764 12.6912 9.49715 14.116 8.52419M10.4112 2.10274L5.7143 3.79883C4.71244 4.16062 4.03009 5.09384 3.98915 6.15823L3.87821 9.04263C3.74074 12.6167 5.60374 15.9703 8.71089 17.7419L10.0366 18.4978C10.8219 18.9456 11.7828 18.956 12.5777 18.5255L13.8755 17.8225C17.2035 16.0199 19.1599 12.4285 18.8696 8.65482L18.6717 6.08259C18.5928 5.05687 17.9199 4.17268 16.9523 3.82327L12.1878 2.10274C11.6137 1.89544 10.9852 1.89544 10.4112 2.10274Z" stroke="#22272B" stroke-width="1.30769" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                      Privacy Policy
                  </Link>
                </div>
              </div>

              <div className='flex items-start self-stretch'>
                <div className='flex p-[10px] pt-0 pb-0 gap-[10px] justify-start items-center self-stretch w-full'>

                  <h4 className='text-[#22272B] dark:text-[#FEFEFE] text-left font-TTregular text-[16px] font-medium leading-[22px]'>
                  By pressing send you are consenting to our Privacy Policy.
                  </h4>

                </div>
              </div>

              <div className='flex flex-col justify-center items-center gap-[20px] self-stretch md:flex-row md:gap-5'>
                
                <button className='border-[#60B6B3] border-[1px] bg-[#001A4F] dark:bg-[#99ECF3] rounded-[6px] w-[144px] h-[30px] text-[#FEFEFE] dark:text-[#001A4F] text-center text-[12px] font-semibold leading-[16px]' 
                  onClick={() => {
                    setIsClickedPrivacyPolicy(true)
                    document.body.style.overflow = 'auto'
                    handleSubmit(new Event('submit'))
                  }}
                >
                  I agree
                </button>
                
                <button 
                  className='dark:border-[#60B6B3] border-[1px] rounded-[6px] w-[144px] h-[30px] text-[#22272B] dark:text-[#FEFEFE] text-center text-[12px] font-semibold leading-[16px]
                
                  bg-gradient-to-b from-[rgba(252,252,255,0)] to-[rgba(225,228,237,0.3)] bg-[#F8FAFF]

                  shadow-[0px_2px_4px_0px_rgba(18,55,104,0.12),0px_0px_0px_1.5px_rgba(18,55,104,0.05),0px_1px_0px_0px_rgba(18,55,104,0.05),0px_0px_0px_1px_rgba(255,255,255,0.5)_inset,0px_1px_0px_0px_#FFF_inset]

                  dark:bg-gradient-to-b dark:to-[rgba(61,63,68,0.31)] dark:bg-[#2C333A]
                
                  dark:shadow-[0px_1px_0px_0px_rgba(69,79,89,0.15)_inset,0px_0px_0px_1px_rgba(69,79,89,0.30)_inset,0px_1px_0px_0px_rgba(69,79,89,0.95),0px_0px_0px_1px_rgba(69,79,89,0.95),0px_2px_4px_0px_rgba(44,51,58,0.12)]' 
                  onClick={handleCloseModal}
                >
                  I disagree
                </button>

              </div>
            </div>
          </div>

    </div>
  )
}

export default Careers
