import { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./CardsCarousel.css"
import TeamCard from '../teamCard/TeamCard'

interface ExtendedSlider extends Slider {
    slickNext: () => void;
    slickPrev: () => void;
}

const CardsCarousel = () => {
    const sliderRef = useRef<ExtendedSlider | null>(null)
    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext()
          }
    }
    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev()
        }
    }

  useEffect(() => {
    const transitionButtons = document.getElementById('transitionButtons')
    const transitionButtons2 = document.getElementById('transitionButtons2')

    const carouselElement = document.querySelector('.cards-carousel')

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (transitionButtons && transitionButtons2) {
            if (entry.isIntersecting) {
              transitionButtons.style.visibility = 'hidden'
              transitionButtons2.style.visibility = 'hidden'

            } else {
              transitionButtons.style.visibility = 'visible'
              transitionButtons2.style.visibility = 'visible'

            }
          }
        });
      },
      { threshold: 1 } 
    )

    if (carouselElement) {
      observer.observe(carouselElement)
    }

    return () => {
      if (carouselElement) {
        observer.unobserve(carouselElement)
      }
    }
  }, [])

    const [cardsToShow, setCardsToShow] = useState('4.2')

    useEffect(() => {
      const updateCardsToShow = () => {
        if (window.innerWidth < 360) {
            setCardsToShow('1')
            } else if (window.innerWidth < 400) {
            setCardsToShow('1.5')
            } else if (window.innerWidth < 768) {
            setCardsToShow('1.7')
            } else if (window.innerWidth <= 1024) {
            setCardsToShow('2.7')
            } else if (window.innerWidth <= 1380) {
            setCardsToShow('3.2')
            } else if (window.innerWidth <= 1700) {
            setCardsToShow('4.3')
            } else if (window.innerWidth <= 2100) {
            setCardsToShow('5.2')
            } else if (window.innerWidth <= 2500) {
            setCardsToShow('6.2')
            } else if (window.innerWidth <= 3000) {
            setCardsToShow('7.2')
            } else if (window.innerWidth <= 3500) {
            setCardsToShow('8.2')
            } 
      }
  
      updateCardsToShow();
      window.addEventListener('resize', updateCardsToShow)
  
      return () => {
        window.removeEventListener('resize', updateCardsToShow)
      };
    }, [window.innerWidth])

const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: parseFloat(cardsToShow),
    swipeToSlide: true,
    arrows: false,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000
    }

    return (
    <div className='cards-carousel mb-[100px] lg:mb-[300px] h-[400px] md:h-[430px] lg:h-[460px] flex overflow-hidden relative w-full my-[60px] cardsCarouselTestimonialsContainer' >
        <div className='teamGradientLeft md:w-[40px] lg:w-[100px] h-[275px] md:h-[380px] lg:h-[412px]'>
        </div>
        <div className='teamCarrouselContainer w-[100%]  h-[370px] md:h-[400px] lg:h-[460px] '>
            <div className='teamGradientRight md:w-[40px] lg:w-[100px] h-[275px] md:h-[380px] lg:h-[412px]'>
            </div>
        
            <Slider ref={sliderRef} {...settings} >

                <TeamCard
                    profileImage = "/team/predro.jpg"
                    gif = "/team/pedro.gif"
                    name = "PEDRO COELHO"
                    position = "CEO"
                    linkedinLink = "https://www.linkedin.com/in/pmfccoelho"
                />

                <TeamCard
                    profileImage = "/team/clara.jpg"
                    gif = "/team/clara.gif"
                    name = "CLARA BERNARDES"
                    position = "Head of Clinical"
                    linkedinLink = "https://www.linkedin.com/in/claramjbernardes"
                />

                <TeamCard
                    profileImage = "/team/jose.jpg"
                    gif = "/team/jose.gif"
                    name = "JOSE FARIA"
                    position = "Head of Engineering"
                    linkedinLink = "https://www.linkedin.com/in/jose-c-faria"
                />

                <TeamCard
                    profileImage = "/team/diogo.jpg"
                    gif = "/team/diogo.gif"
                    name = "DIOGO PISOEIRO"
                    position = "Head of Design"
                    linkedinLink = "https://www.linkedin.com/in/diogo-pisoeiro-9935b050"
                />

                <TeamCard
                    profileImage = "/team/dennis.png"
                    gif = "/team/dennis.gif"
                    name = "DENNIS VELASQUEZ"
                    position = "Head of Clinical Solutions"
                    linkedinLink = "https://www.linkedin.com/in/dennis-velasquez-81533416"
                    calendarLink = "https://meetings-eu1.hubspot.com/dennis-velasquez?uuid=800c384c-eed3-46bf-8b9f-70141c4a0da6"
                />


                <TeamCard
                    profileImage = "/team/tomas.jpg"
                    gif = "/team/tomas.gif"
                    name = "TOMAS NIEDERHAUSER"
                    position = "Developer Front-end"
                    linkedinLink = "http://linkedin.com/in/tom%C3%A1s-niederhauser-b0847b208"
                />

                <TeamCard
                    profileImage = "/team/hector.jpg"
                    gif = "/team/hector.gif"
                    name = "HECTOR OVALLES"
                    position = "Developer Front-end"
                    linkedinLink = "https://www.linkedin.com/in/hector-ovalles-30a388293"
                />

                <TeamCard
                    profileImage = "/team/pedro-santos.jpg"
                    gif = "/team/pedro-santos.gif"
                    name = "PEDRO SANTOS"
                    position = "Developer Back-end"
                    linkedinLink = "https://www.linkedin.com/in/pmrsantos/"
                />

                <TeamCard
                    profileImage = "/team/joy.jpg"
                    gif = "/team/joy.gif"
                    name = "JOY AWAD"
                    position = "Machine Learning Lead Engineer"
                    linkedinLink = "http://linkedin.com/in/joy-awad"                    
                />

                <TeamCard
                    profileImage = "/team/katerina.png"
                    gif = "/team/katerina.gif"
                    name = "KATERINA DIMITRIADOU"
                    position = "Marketing Manager"
                    linkedinLink = "https://www.linkedin.com/in/katerina-dimitriadou"
                />

                <TeamCard
                    profileImage = "/team/sebas.jpg"
                    gif = "/team/sebas.gif"
                    name = "SEBAS RIGGIO"
                    position = "Digital Product Designer"
                    linkedinLink = "https://www.linkedin.com/in/sebastian-riggio"
                />

            </Slider>
                <div style={{ textAlign: "center" }}>
                    <button className="
                    mt-2 mx-2 p-1 cursor-pointer rounded-full border border-[#5EBABA]/50 hover:border-[#5EBABA] bg-gradient-to-t from-[#A5F6FF]/8 to-[#A5F6FF]/8 backdrop-blur-md z-40
                    
                    " onClick={previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path className="dark:stroke-white/50 dark:group-hover:stroke-white group-hover:stroke-[#001A4F]" d="M15 18C12.8192 16.423 10.8636 14.5771 9.18284 12.5101C8.93905 12.2103 8.93905 11.7897 9.18284 11.4899C10.8636 9.42294 12.8192 7.57701 15 6" stroke="#001A4F80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button className="
                    mt-2 mx-2 p-1 cursor-pointer rounded-full border border-[#5EBABA]/50 hover:border-[#5EBABA] bg-gradient-to-t from-[#A5F6FF]/8 to-[#A5F6FF]/8 backdrop-blur-md z-40
                    " onClick={next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path className="dark:stroke-white/50 dark:group-hover:stroke-white group-hover:stroke-[#001A4F]" d="M9 18C11.1808 16.423 13.1364 14.5771 14.8172 12.5101C15.0609 12.2103 15.0609 11.7897 14.8172 11.4899C13.1364 9.42294 11.1808 7.57701 9 6" stroke="#001A4F80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
        </div>    

    </div>
    )
}

export default CardsCarousel
