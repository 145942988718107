import { useEffect, useState } from 'react';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Carousel.css"


const Carousel: React.FC = () => {

    const [centerPadding, setCenterPadding] = useState('23%');

    useEffect(() => {
      const updateCenterPadding = () => {
        if (window.innerWidth <= 768) {
          setCenterPadding('10%');
        } else {
          setCenterPadding('23%');
        }
      };
  
      updateCenterPadding();
      window.addEventListener('resize', updateCenterPadding);
  
      return () => {
        window.removeEventListener('resize', updateCenterPadding);
      };
    }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true, 
    centerPadding: centerPadding,
  }


  return (
    <div className=' my-15  w-full h-[300px] lg:h-[380px] p-0 mb-[150px] lg:mb-[300px] 2xl:max-w-7xl 2xl:mx-auto' >
      <Slider {...settings}>
        <div>
            <div className='w-[330px] md:w-full lg:w-full flex h-[380px] lg:h-[390px] pt-[20px] mx-0'>
                <div className='h-[336px] lg:h-[340px] flex flex-col justify-center items-start gap-[12px] p-[5px] m-[0px] lg:p-[56px_48px] text-left rounded-[22px] border-[1px] border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[17px] w-[230px] md:w-[300px] lg:w-[650px] xl:w-[580px] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)]'>
                    <div className='flex lg:p-3 self-stretch'>
                        <h3 className='text-[#001A4F] pl-2 font-normal w-[215px] md:w-[300px] h-[145px] md:h-[180px] lg:w-full leading-[15px] md:leading-[23px] lg:leading-[28px] text-[16px] md:text-[18px] lg:text-[18px] dark:text-white'>“The expansive potential of Jarvis hints at a wealth of untapped applications beyond drug development; it opens the door to revolutionary possibilities that could help redefine efficiency and precision in the broader medical field.”</h3>
                    </div>
                    <div className='block lg:flex justify-center items-center gap-[10px] w-[215px] lg:w-[400px] xl:w-[500px] mt-[40px] lg:mt-[10px]'>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#001A4F] dark:text-[#99ECF3] text-[16px] lg:text-[18px] font-medium lg:leading-[29px]'>
                                Ricardo Matias
                            </p>
                        </div>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#161A1D] font-normal leading-6 text-[13px] lg:text-[18px] dark:text-white'>
                                CRIO, Co-Founder of kinetikos
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex w-[150px] lg:w-[200px] h-[235px] md:h-[240px] lg:h-[270px] flex-col justify-end items-center rounded-[12px] bg-[#BDBFBE] ml-[-70px] lg:ml-[-70px] mt-[35px] mb-[20px]'>
                    <img className='h-full w-full rounded-[12px]' src="/ricardoMatias.png" alt="" />
                </div>
            </div>
        </div>

        <div>
            <div className='w-[330px] md:w-full lg:w-full flex h-[380px] lg:h-[390px] pt-[20px] mx-0'>
                <div className='h-[336px] lg:h-[340px] flex flex-col justify-center items-start gap-[12px] p-[5px] m-[0px] lg:p-[56px_48px] text-left rounded-[22px] border-[1px] border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[17px] w-[230px] md:w-[300px] lg:w-[650px] xl:w-[580px] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)]'>
                    <div className='flex lg:p-3 self-stretch'>
                        <h3 className='text-[#001A4F] pl-2 font-normal w-[215px] md:w-[300px] h-[145px] md:h-[180px] lg:w-full leading-[15px] md:leading-[23px] lg:leading-[28px] text-[16px] md:text-[18px] lg:text-[18px] dark:text-white'>Overall, I would say that the identification of key studies was very helpful, and would allow me to track down even more meaningful information.I am impressed with what Jarvis can do now, but I think there is room for growth.”</h3>
                    </div>
                    <div className='block lg:flex justify-center items-center gap-[10px] w-[215px] lg:w-[400px] xl:w-[500px] mt-[40px] lg:mt-[10px]'>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#001A4F] dark:text-[#99ECF3] text-[16px] lg:text-[18px] font-medium lg:leading-[29px]'>
                                Ronenn Roubenoff
                            </p>
                        </div>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#161A1D] font-normal leading-6 text-[13px] lg:text-[18px] dark:text-white'>
                                Ex-VP of Novartis
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex w-[150px] lg:w-[200px] h-[235px] md:h-[240px] lg:h-[270px] flex-col justify-end items-center rounded-[12px] bg-[#BDBFBE] ml-[-70px] lg:ml-[-70px] mt-[35px] mb-[20px]'>
                    <img className='h-full w-full rounded-[12px]' src="/ronennRoubenoff.png" alt="" />
                </div>
            </div>
        </div>

        <div>
            <div className='w-[330px] md:w-full lg:w-full flex h-[380px] lg:h-[390px] pt-[20px] mx-0'>
                <div className='h-[336px] lg:h-[340px] flex flex-col justify-center items-start gap-[12px] p-[5px] m-[0px] lg:p-[56px_48px] text-left rounded-[22px] border-[1px] border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[17px] w-[230px] md:w-[300px] lg:w-[650px] xl:w-[580px] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)]'>
                    <div className='flex lg:p-3 self-stretch'>
                        <h3 className='text-[#001A4F] pl-2 font-normal w-[215px] md:w-[300px] h-[145px] md:h-[180px] lg:w-full leading-[15px] md:leading-[23px] lg:leading-[28px] text-[16px] md:text-[18px] lg:text-[18px] dark:text-white'>“I think this is a fantastic tool for clinical trialists that will not only save time but could potentially reduce the number of avoidable protocol amendments, saving costs and enhancing confidence in evaluation of the study objectives.”</h3>
                    </div>
                    <div className='block lg:flex justify-center items-center gap-[10px] w-[215px] lg:w-[400px] xl:w-[500px] mt-[40px] lg:mt-[10px]'>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#001A4F] dark:text-[#99ECF3] text-[16px] lg:text-[18px] font-medium lg:leading-[29px]'>
                                Laurence Skillern
                            </p>
                        </div>
                        <div className='flex pl-2 lg:p-3 justify-start lg:justify-center items-center gap-3'>
                            <p className='text-[#161A1D] font-normal leading-6 text-[13px] lg:text-[16px] dark:text-white'>
                                Medical Director | CMO of ISTESSO
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex w-[150px] lg:w-[200px] h-[235px] md:h-[240px] lg:h-[270px] flex-col justify-end items-center rounded-[12px] bg-[#BDBFBE] ml-[-70px] lg:ml-[-70px] mt-[35px] mb-[20px]'>
                    <img className='h-full w-full rounded-[12px]' src="/laurenceSkillern.png" alt="" />
                </div>
            </div>
        </div>

      </Slider>
    </div>
  )
}

export default Carousel