


const TextAndImg = (prop:any) => {

    const title = prop.title
    const text = prop.text
    const image = prop.image


  return (
    <div>
        <div className="textAndImgContainer block md:flex lg:flex items-center self-stretch ml-[30px] mt-10">
            <h3 className="textAndImgText">
                {title}
                {text}
            </h3>
            <div className="textAndImgImg">
                {image}
            </div>
        </div>
    </div>
  )
}

export default TextAndImg
