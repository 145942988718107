import { useRef, useState, useEffect } from 'react'
import ButtonAccessJarvis from "../buttonAccessJarvis/ButtonAccessJarvis"

const BannerHome = () => {
    const [showSecondVideo, setShowSecondVideo] = useState(false)
    const [showSecondVideoCell, setShowSecondVideoCell] = useState(false)
    const firstVideoDesktopRef = useRef<HTMLVideoElement>(null);
    const firstVideoMobileRef = useRef<HTMLVideoElement>(null);
    const secondVideoDesktopRef = useRef<HTMLVideoElement>(null);
    const secondVideoMobileRef = useRef<HTMLVideoElement>(null);
  
    useEffect(() => {
        const firstVideoDesktop = firstVideoDesktopRef.current;
        const firstVideoMobile = firstVideoMobileRef.current;
    
        if (firstVideoDesktop) {
          firstVideoDesktop.addEventListener('ended', handleFirstVideoEnded);
        }
        if (firstVideoMobile) {
          firstVideoMobile.addEventListener('ended', handleFirstVideoEnded2);
        }
    
        return () => {
          if (firstVideoDesktop) {
            firstVideoDesktop.removeEventListener('ended', handleFirstVideoEnded);
          }
          if (firstVideoMobile) {
            firstVideoMobile.removeEventListener('ended', handleFirstVideoEnded2);
          }
        }
    }, [])
  
    const handleFirstVideoEnded = () => {
      setShowSecondVideo(true)
    }
  
    const handleFirstVideoEnded2 = () => {
        setShowSecondVideoCell(true)
      }

  return (
    <div className="mb-[150px] lg:mb-[300px] xl:mx-20 2xl:max-w-7xl 2xl:mx-auto">
        <div className=" p-5 lg:p-10 xl:p-0 xl:pt-10 m-5 mt-[270px] lg:m-10 xl:m-0">
            <div className="flex flex-col justify-center items-center lg:items-start gap-8 lg:gap-12">
                <div className="flex flex-col justify-center lg:justify-center items-center lg:items-start gap-0 lg:h-[164px] self-stretch">
                    <h2 className="text-[#001A4F] font-TTBold text-[30px] lg:text-6xl font-bold lg:font-semibold leading-[20px] lg:leading-[78px] dark:text-[#99ECF3]">
                        Faster & Smarter
                    </h2>
                    <h2 className="text-[#001A4F] text-[30px] font-TTBold lg:text-6xl font-semibold lg:leading-[78px] dark:text-[#99ECF3]">
                        Drug Development
                    </h2>
                </div>
                <div className="flex p-0 items-center gap-10 self-stretch justify-center lg:justify-start">
                    <p className="hidden md:block text-[#161A1D] md:ml-0  text-center lg:text-start text-[20px] lg:text-base font-normal leading-[28px] dark:text-white">
                        Revolutionizing drug development throught the power of AI. <br/>
                        We have create the <strong className="font-semibold font-TTBold">first Clinical AI Assistant.</strong>
                    </p>
                    <p className="text-[#161A1D] md:hidden text-center text-[20px] font-normal leading-[28px] dark:text-white">
                        Revolutionizing drug development <br/>
                        throught the power of AI. <br/>
                        We have create the <br/>
                        <strong className="font-TTBold dark:text-[#99ECF3]">First Clinical AI Assistant.</strong>
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center lg:items-start gap-10 self-stretch p-0 px-15">
                    <ButtonAccessJarvis/>
                </div>
            </div>
        </div>
        <div className="justify-center items-center absolute 
        mx-[20%]
        sm:mx-[28%] 
        md:mx-[28%] 2xl:mx-auto
        lg:ml-0 lg:inset-0 
        z-[-1] md:left-[-40px] 
        md:top-[20px] lg:top-0 
        w-[300px] sm:w-[425px] md:w-[500px] lg:w-full xl:max-w-7xl
        "
        >
        
            <video
            ref={firstVideoDesktopRef}
            className={` w-full mt-[150px] lg:mt-[50px] mx-[-20%] lg:mx-[50px] ${showSecondVideo ? 'hidden' : 'hidden md:block'}`}
            playsInline
            autoPlay
            muted
            typeof="video/web"
            src="/video desktop biorce sin fondo baja 1.mov"
            ></video>
            
            <video
            ref={secondVideoDesktopRef}
            className={`w-full mt-[150px] lg:mt-[50px] mx-[-20%] lg:mx-[50px] ${showSecondVideo ? 'hidden md:block' : 'hidden'}`}
            playsInline
            autoPlay
            muted
            loop
            typeof="video/web"
            src="/video desktop biorce sin fondo baja 2.mov"
            ></video>

            <video
            ref={firstVideoMobileRef}
            className={`w-2/3 mt-[-660px] ${showSecondVideoCell ? 'hidden' : 'block md:hidden'}`}
            playsInline
            autoPlay
            muted
            typeof="video/web"
            src="/Biorce video mobile  without background alta loop 1.mov"
            ></video>

            <video
            ref={secondVideoMobileRef}
            className={`w-2/3 mt-[-660px] ${showSecondVideoCell ? 'block md:hidden' : 'hidden'}`}
            playsInline
            autoPlay
            muted
            loop
            typeof="video/web"
            src="/Biorce video mobile  without background alta loop 2.mov"
            ></video>

        </div>
    </div>
  )
}

export default BannerHome
