import { Link } from "react-router-dom"
import  "./TeamCard.css"

const TeamCard = (prop:any) => {

    const profileImage = prop.profileImage
    const gif = prop.gif
    const name = prop.name
    const position = prop.position
    const linkedinLink = prop.linkedinLink
    const calendarLink = prop.calendarLink
    

  return (

    <div className='w-[100%]   md:h-[380px]  lg:h-[410px]'>
        <div className='flex ml-[30px]  w-[220px] md:w-[280px] lg:w-[320px] h-[275px] md:h-[380px] lg:h-[409px] flex-col rounded-[15px] backdrop-blur-[30px]'>
            <div className='teamCardContainer z-0 w-[220px] md:w-[280px] lg:w-[320px] h-[275px] lg:h-[409px] rounded-[12px]'>
                <img className="absolute w-full h-full lg:h-[409px] rounded-[12px]  z-0" src={`${profileImage}`} alt="" />

                <img className="absolute teamGif w-full h-full lg:h-full rounded-[12px] z-10 mt-[450px] transition-all duration-1000" src={`${gif}`} alt="" />

                <div className='filterCardCarousel w-full mt-[450px] h-[275px] md:h-[380px] lg:h-[409px] z-20 transition-all duration-1000'>
                </div>

                <div className='filterTeamCardText w-[220px] md:w-[280px] lg:w-[320px] h-[275px] md:h-[380px] lg:h-[409px] mt-[450px] z-[500] transition-all duration-1000'>
                        <Link to={`${linkedinLink}`} target="_blank" className='absolute top-[5px] md:top-[7px] lg:top-2 right-[5px] md:right-[7px] lg:right-2 cursor-pointer fill-current text-white'>
                            <svg className="w-[17px] md:w-[20px] lg:w-[25px] h-[17px] md:h-[20px] lg:h-[25px]" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 25" fill="none">
                                <path d="M17.6245 17.6236V13.995C17.6245 12.5636 16.4641 11.4032 15.0327 11.4032C13.6013 11.4032 12.4409 12.5636 12.4409 13.995M12.4409 13.995V17.6236M12.4409 13.995V10.8848M8.29399 10.8848V17.6236M8.29399 7.25628V7.26665M11.4042 21.7705H13.4776C16.3807 21.7705 17.8323 21.7705 18.9411 21.2055C19.9165 20.7085 20.7095 19.9155 21.2065 18.9402C21.7714 17.8313 21.7714 16.3798 21.7714 13.4766V11.4032C21.7714 8.50008 21.7714 7.04853 21.2065 5.93969C20.7095 4.96433 19.9165 4.17133 18.9411 3.67436C17.8323 3.10938 16.3807 3.10938 13.4776 3.10938H11.4042C8.50106 3.10938 7.04951 3.10938 5.94067 3.67436C4.9653 4.17133 4.17231 4.96433 3.67533 5.93969C3.11035 7.04853 3.11035 8.50008 3.11035 11.4032V13.4766C3.11035 16.3798 3.11035 17.8313 3.67533 18.9402C4.17231 19.9155 4.9653 20.7085 5.94067 21.2055C7.04951 21.7705 8.50106 21.7705 11.4042 21.7705Z" stroke="white" stroke-width="2.07345" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </Link>
                        <div>
                            <h3 className="text-[#FFF] absolute bottom-[20px] md:bottom-[30px] lg:bottom-[40px] left-[5px] md:left-[7px] lg:left-[10px] z-[30] text-[10px] lg:text-[17px] font-semibold leading-[18px]">{name}</h3>
                            <h4 className="text-[#FFF] absolute bottom-[5px] md:bottom-[15px] lg:bottom-[20px] left-[5px] md:left-[7px] lg:left-[10px] z-[30] text-[9px] lg:text-[13px] font-normal leading-[18px]">{position}</h4>
                        </div>
                        {calendarLink && (
                        <Link to={`${calendarLink}`} target="_blank"> 
                            <svg className="absolute bottom-[20px] md:bottom-[30px] lg:bottom-[38px] right-[55px] md:right-[57px] lg:right-[92px] z-[30] w-[17px] md:w-[20px] lg:w-[23px] h-[17px] md:h-[20px] lg:h-[23px]" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                <path d="M19.9188 9.50306C19.9571 10.2517 19.9571 11.1713 19.9571 12.3541C19.9571 15.0109 19.9571 16.3393 19.523 17.3871C18.9443 18.7843 17.8343 19.8943 16.4371 20.473C15.3893 20.9071 14.0609 20.9071 11.4041 20.9071C8.74728 20.9071 7.41888 20.9071 6.37101 20.473C4.97386 19.8943 3.86383 18.7843 3.28511 17.3871C2.85107 16.3393 2.85107 15.0109 2.85107 12.3541C2.85107 11.1713 2.85107 10.2517 2.88937 9.50306M19.9188 9.50306C19.871 8.57006 19.7638 7.90235 19.523 7.321C18.9443 5.92385 17.8343 4.81381 16.4371 4.2351C16.0746 4.08493 15.6785 3.98672 15.2054 3.92248M19.9188 9.50306L2.88937 9.50306M2.88937 9.50306C2.9371 8.57006 3.04431 7.90235 3.28511 7.321C3.86383 5.92385 4.97386 4.81381 6.37101 4.2351C6.73355 4.08493 7.12966 3.98672 7.60274 3.92248M7.60274 1.90039V3.92248M7.60274 3.92248V5.70172M7.60274 3.92248C8.49706 3.80106 9.66645 3.80106 11.4041 3.80106C13.1417 3.80106 14.3111 3.80106 15.2054 3.92248M15.2054 1.90039V3.92248M15.2054 3.92248V5.70172M7.61224 13.3044H7.60274M7.61224 17.1057H7.60274M11.4136 13.3044H11.4041M11.4136 17.1057H11.4041M15.2149 13.3044H15.2054M15.2149 17.1057H15.2054" stroke="#FEFEFE" stroke-width="1.55509" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h4 className="flex items-center text-white absolute bottom-[20px] md:bottom-[30px] lg:bottom-[38px] right-[5px] md:right-[7px] lg:right-[10px] text-[10px] lg:text-[17px] z-[30]"> book meet</h4>
                        </Link>
                        )}
                </div>

                <button className="ml-[28%] clickMeButton absolute border-[1px] border-[rgba(94,186,186,0.5)] flex bg-[#22272B] w-[90px] lg:hidden h-[30px] bottom-5 rounded-[12px] items-center justify-center">
                    <svg className="w-0 lg:w-[17px] h-0 lg:h-[17px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" fill="none">
                        <path d="M8.06896 3.96336V2.19678M11.4223 4.83357L12.4635 3.86821M3.86717 8.1651H2.10059M5.09779 5.19403L3.61225 3.70849M3.77202 12.5597L4.73738 11.5185M12.7833 11.6763C12.5932 11.7392 12.4982 11.7706 12.4124 11.8117C12.1076 11.958 11.8618 12.2038 11.7156 12.5086C11.6744 12.5944 11.643 12.6894 11.5802 12.8794C11.2302 13.9378 11.0552 14.4669 10.8897 14.6745C10.2664 15.4562 9.06441 15.4115 8.50096 14.5856C8.35134 14.3663 8.21617 13.8256 7.94582 12.7442L7.33921 10.3178C7.02231 9.05015 6.86385 8.41634 7.03684 7.9746C7.18782 7.58905 7.49286 7.28401 7.87841 7.13303C8.32015 6.96004 8.95396 7.1185 10.2216 7.4354L12.648 8.04201C13.7294 8.31235 14.2701 8.44753 14.4894 8.59715C15.3153 9.1606 15.3601 10.3626 14.5783 10.9859C14.3707 11.1514 13.8416 11.3264 12.7833 11.6763Z" stroke="#99ECF3" stroke-width="2.0585" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div className="text-[12px] font-medium leading-[18px] text-[#99ECF3] normal-case ml-1">
                        Click me
                    </div>
                </button>  
            </div>

        </div>
    </div>

  )
}

export default TeamCard
